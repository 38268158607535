import * as React from "react"
import { graphql } from "gatsby"
import Masonry from "react-masonry-component"
import MasonryCard from "../components/masonry-card/masonry-card"
import Pagination from "../components/pagination/pagination"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlogPostsWrapper, PostRow, PostCol } from "./templates.style"

const BlogList = (props: any) => {
  const { data } = props
  const Posts = data.allGhostPost.edges
  const { currentPage, numPages } = props.pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? "/page/1" : `/page/${(currentPage - 1).toString()}`
  const nextPage = `/page/${(currentPage + 1).toString()}`
  const PrevLink = !isFirst && prevPage
  const NextLink = !isLast && nextPage

  return (
    <Layout>
      <SEO title={`Page ${currentPage}`} />

      <BlogPostsWrapper>
        <PostRow>
          <Masonry className="showcase">
            {Posts.map(({ node }: any) => {
              return (
                <PostCol key={node.slug}>
                  <MasonryCard
                    title={node.title || node.slug}
                    image={
                      node.feature_image_sharp == null
                        ? null
                        : node.feature_image_sharp.childImageSharp.fluid
                    }
                    url={node.slug}
                    date={node.published_at}
                    tags={node.tags.map(({ slug }: any) => slug)}
                    readTime={''}
                  />
                </PostCol>
              )
            })}
          </Masonry>
        </PostRow>

        <Pagination
          prevLink={PrevLink}
          nextLink={NextLink}
          currentPage={`${currentPage}`}
          totalPage={`${numPages}`}
        />
      </BlogPostsWrapper>
    </Layout>
  )
}

export default BlogList

export const pageQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    sitePage {
      path
    }
    allGhostPost(filter: {slug: {ne: "data-schema"}}, sort: {fields: published_at, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          excerpt
          slug
          published_at(formatString: "DD [<span>] MMMM [</span>]")
          title
          meta_description
          tags {
            slug
          }
          feature_image_sharp {
            childImageSharp {
              fluid(maxWidth: 1170, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
